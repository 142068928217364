import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const beckendLiveApiKYCUrl = process.env.REACT_APP_KYC_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  var Kycurl
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;     
    Kycurl = beckendLiveApiKYCUrl;     
  } else {
    baseUrl = beckendLiveApiUrl;
    Kycurl = beckendLiveApiKYCUrl;
  }
  const [userAllData, setUserAllData] = useState();
  const [commission, setcommission] = useState();
  const [refer, setrefer] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [blockMessage, setBlockMessage] = useState("");
  const [signUpmsg, setSignMSG] = useState("");
  const [isBonus, setisBonus] = useState("");
  const [userBlock, setUserBlock] = useState(0);
  const [msg, setmsg] = useState("");
  const [banners, setBanners] = useState([]);

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setcommission(data.commission);
    setrefer(data.refer);
    setmsg(data.msg);
    setSignMSG(data.bonusSignup);
    setisBonus(data.isBonus)
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        const passworduser = res.data.Password;
        
      })

      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          
        }
      });
  };

useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "true") {
      
    }

    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, [userBlock]);



  const fetchBanners = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const response = await axios.get(Kycurl + `banner-list`, { headers });
      setBanners(response.data.banner);
    } catch (e) {
      if (e.response?.status === 401) {
        localStorage.removeItem("token");
      }
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrow:false,
  };

  return (
    <>
      {userBlock > 0 ? <div>{blockMessage}</div> : <div>Login Panel</div>}
      <div className="leftContainer">
        <div className="main-area">
            {(signUpmsg > 0 && isBonus == true) ? (
            <div className="header_top_message msgsign mb-2">
              Special Offer!😍 Sign up and get ₹{signUpmsg} instantly! 😍
              </div>
             ) : null}
          <div className="header_top_message">
            <span>Commission:5% ◉ Referral: 3% For All Games</span>
          </div>
          <div className="header_top_message">
            <span>{msg}</span>
          </div>

            <section className="mt-3 bannerslider">
            {banners.length >= 1 ? (
              <Slider {...settings}>
                {banners.map((banner, index) => (
                  <div key={index} className="image_height">
                    <img
                      src={`https://kycjvt.rushkingludo.com/${banner.media}`}
                      alt={`Banner ${index + 1}`}
                      
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <p>Loading...</p>
            )}
          </section>

          <section className="games-section p-3">
            <div className="d-flex align-items-center games-section-title"></div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
                  
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/Ludo1.png"
                      }
                      
                      alt=""
                    />
                  </picture>
                  
                </Link>
                
                <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/Ludo2.png"
                      }
                      alt=""
                    />
                  </picture>
                  
                </Link>

                <Link className="gameCard-container" to={`/tournament`}>
                  
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/tournament.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>


                <Link className="gameCard-container">
                  
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/circketimage.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>


                <Link className="gameCard-container">
                  
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/snake.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>

                <Link className="gameCard-container">
                  
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={process.env.PUBLIC_URL + "/Images/Homepage/card.png"}
                      alt="image"
                    />
                  </picture>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
